/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { MouseEventHandler, useState } from 'react';
import { useForm, SubmitHandler, Controller, FieldError } from 'react-hook-form';
import i18n from 'i18next';
import { styled } from '@material-ui/core/styles';

import { TranslationNamespace } from 'i18n/config';
import {
  emailRegex,
  saudiPhoneNumberRegex,
  phoneNumberRegex,
  taxNumberRegex,
  numberPlusRegex,
  numberRegex,
  enNameRegex,
} from 'infrastructure/constants/regex.constants';
import { useNavigate } from 'utilities/navigate';
import { useCreateTranslate } from 'utilities/translate.hook';
import { EconomicActivity } from 'types/EconomicActivity';
import {
  CompanyRepresentativeRoutes,
  getCompanyRepresentativePath,
} from 'domain/company-representative/CompanyRepresentativeRoutes';
import { useFormStyles } from 'styles/form';
import { Map } from 'ui/Map';
import { CheckGreyIcon, CrossCloseGreyIcon } from 'ui/icons/icons';
import { Hint } from 'ui/Hint';

import { CrFillMissingInfoCity } from './CrFillMissingInfoCity';
import { FormValues, toEstablishmentInfoDto } from '../types/MissingInfo';
import { Region } from '../types/Localization';
import { useAddEstablishmentInfoMutation, useVATAttachmentMutation } from '../api/companyRepresentative.api';
import { getDirection } from '../../../utilities/useLanguage';

const Input = styled('input')({
  display: 'none',
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  wrapper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      paddingInline: 120,
      paddingBlock: 40,
    },
  },
  inputField: {
    marginBlock: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginBlock: theme.spacing(2),
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  terms: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'stretch',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      marginInline: -theme.spacing(1),
    },
  },
  button: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      flexDirection: 'row-reverse',
      marginInline: theme.spacing(1),
    },
  },
  topic: {
    marginBottom: theme.spacing(1.5),
  },
  topicDescription: {
    color: theme.palette.text.secondary,
  },
  VARSection: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    fontSize: 16,
  },
  headerWrapper: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  sectionHeader: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  language: {
    color: theme.palette.common.white,
  },
  map: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7),
    },
  },
  field: {
    marginTop: theme.spacing(4),
  },
  uploadButton: {
    // flip: false,
    // marginLeft: theme.spacing(0),
    fontSize: '18px',
    height: 45,
    [theme.breakpoints.up('md')]: {
      width: 150,
    },
    uploadedContainer: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  uploadButtonSpace: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  uploadButtonDesc: {
    width: '340px',
    marginLeft: theme.spacing(4),
    paddingRight: theme.spacing(12),
    textAlign: 'left',
  },
  uploadedContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
  },
  attachment: {
    flip: false,
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  taxField: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
}));

export interface FillMissingInfoFormProps {
  formValues: Partial<FormValues>;
  economicActivities: EconomicActivity[];
  regionOptions: Region[];
}

export const CrFillMissingInfoForm: React.FC<FillMissingInfoFormProps> = ({
  formValues,
  economicActivities,
  regionOptions,
}) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const navigate = useNavigate();
  const [putEstablishmentInfo] = useAddEstablishmentInfoMutation();
  const [VATAttachment] = useVATAttachmentMutation();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const direction = getDirection(i18n.language);
  const [attachments, setAttachment] = useState<File[]>([]);
  const inputFields = [
    { name: 'district', label: 'district' },
    { name: 'street', label: 'street' },
    { name: 'buildingNumber', label: 'buildingNumber', pattern: numberRegex },
    { name: 'postalCode', label: 'postalCode', pattern: numberRegex },
    { name: 'additionalNumber', label: 'additionalNumber', pattern: numberRegex },
  ];
  const [hasTaxNumber, setHasTaxNumber] = useState<string>(''); // Track selected radio value

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: formValues,
  });

  const preventDefaultIfEnterClicked = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const preventDefaultIfNotNumberAndPlusValues = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!numberPlusRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventDefaultIfNotNumberValues = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!numberRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const log = () => {
    // eslint-disable-next-line no-console
    console.log(formValues);
  };

  const onSubmit: SubmitHandler<FormValues> = async (v) => {
    console.log(v);
    const vatAttachmentSuccess = await submitVATAttachment(v.id);
    if (vatAttachmentSuccess) {
      putEstablishmentInfo({ establishmentId: v.id, establishmentInfo: toEstablishmentInfoDto(v) })
        .unwrap()
        .then((payload) =>
          navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/OSH/${v.id}`)
        )
        // eslint-disable-next-line no-console
        .catch((error) => console.error('Submit error: ', error));
    }
  };

  const getErrorText = (type: FieldError['type'] | undefined) => {
    switch (type) {
      case 'required':
        return translate('fieldRequired');
      case 'pattern':
        return translate('invalidFormat');
      default:
        return '';
    }
  };

  const handleFileChange = async ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length) {
      setAttachment((existing) => existing.concat(Array.from(files)));
    }
  };

  const removeAttachment = (id: number) => {
    return setAttachment(
      attachments.filter((item, i) => {
        return i !== id;
      })
    );
  };

  const translation = useCreateTranslate(TranslationNamespace.admin);
  const goBack: MouseEventHandler = (e) => {
    navigate(getCompanyRepresentativePath(CompanyRepresentativeRoutes.companySelect));
  };

  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateCommon('fieldRequired');
      }

      return error.message;
    }

    return ' ';
  };

  const handleVATChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasTaxNumber(event.target.value);
  };

  const submitVATAttachment = async (establishmentId: number) => {
    if (attachments.length === 0) {
      console.error('No attachments found');
      return false;
    }

    const formData = new FormData();
    formData.append('file', attachments[0], attachments[0].name);

    try {
      const response = await VATAttachment({ establishmentId: establishmentId, data: formData });
      if ('error' in response) {
        console.error('Error submitting VATAttachment:', response.error);
        return false;
      } else {
        console.log('VATAttachment response:', response.data);
        return true;
      }
    } catch (error) {
      console.error('Error submitting VATAttachment:', error);
      return false;
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <Typography variant="h5" className={classes.topic}>
          {translate('fillCompanyDetails')}
        </Typography>
        <Typography variant="body2" className={classes.topicDescription}>
          {translate('completeInformation')}
        </Typography>
      </div>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => preventDefaultIfEnterClicked(e)}
      >
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('CRDetails')}</Typography>
          </div>
          <Controller
            name="companyRepresentativeName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.companyRepresentativeName}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.companyRepresentativeName?.type}
                helperText={getErrorText(errors.companyRepresentativeName?.type)}
                label={translate('CRName')}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: emailRegex }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.email}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.email?.type}
                helperText={getErrorText(errors.email?.type)}
                label={translate('email')}
              />
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: saudiPhoneNumberRegex,
                message: translation('invalidPhoneFormat'),
              },
              min: 0,
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                variant="outlined"
                type="text"
                inputMode="numeric"
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translateCommon('phoneNumber')}
                placeholder={translation('formatPhoneField')}
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />

          <Controller
            name="phoneNumberAdditional"
            control={control}
            rules={{
              pattern: {
                value: saudiPhoneNumberRegex,
                message: translation('invalidPhoneFormat'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                variant="outlined"
                type="text"
                inputMode="numeric"
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('phoneNumberAdditional')}
                placeholder={translation('formatPhoneField')}
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('companyDetails')}</Typography>
          </div>

          <Controller
            name="extId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.extId}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.extId?.type}
                helperText={getErrorText(errors.extId?.type)}
                label={translate('establishmentNumber')}
              />
            )}
          />

          <Controller
            name="nameAr"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.nameAr}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.nameAr?.type}
                helperText={getErrorText(errors.nameAr?.type)}
                label={translate('companyNameAr')}
              />
            )}
          />

          <Controller
            name="nameEn"
            control={control}
            rules={{ required: true, pattern: enNameRegex }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.nameEn}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.nameEn?.type}
                helperText={getErrorText(errors.nameEn?.type)}
                label={translate('companyNameEn')}
              />
            )}
          />

          <Controller
            name="mobile"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: saudiPhoneNumberRegex,
                message: translation('invalidPhoneFormat'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                variant="outlined"
                type="text"
                inputMode="numeric"
                dir={direction}
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translateCommon('phoneNumber')}
                placeholder={translation('formatPhoneField')}
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            rules={{ pattern: phoneNumberRegex }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.phone}
                variant="outlined"
                type="text"
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                className={classes.inputField}
                error={!!errors.phone?.type}
                helperText={getErrorText(errors.phone?.type)}
                label={translate('companyPhoneNumber')}
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />

          <Controller
            name="economicActivityNameAr"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.economicActivityNameAr}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.economicActivityNameAr?.type}
                helperText={getErrorText(errors.economicActivityNameAr?.type)}
                label={translate('economicActivity')}
              />
            )}
          />

          <Controller
            name="commercialRegistration"
            control={control}
            rules={{
              required: true,
              pattern: numberRegex,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.commercialRegistration}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.commercialRegistration?.type}
                helperText={getErrorText(errors.commercialRegistration?.type)}
                label={translate('commercialRegistration')}
              />
            )}
          />

          <Typography variant="body2" className={classes.VARSection}>
            {translate('haveAVAT')}
          </Typography>
          <FormControl>
            <RadioGroup value={hasTaxNumber} onChange={handleVATChange}>
              <FormControlLabel value="yes" control={<Radio />} label={translateCommon('yes')} />
              <FormControlLabel value="no" control={<Radio />} label={translateCommon('no')} />
            </RadioGroup>
          </FormControl>
          <Collapse in={hasTaxNumber === 'yes'} className={classes.taxField}>
            <Controller
              name="taxNumber"
              control={control}
              rules={{ pattern: taxNumberRegex }}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={!!formValues.taxNumber}
                  variant="outlined"
                  className={classes.taxField}
                  error={!!errors.taxNumber?.type}
                  helperText={getErrorText(errors.taxNumber?.type)}
                  label={translate('taxNumber')}
                  onKeyPress={preventDefaultIfNotNumberValues}
                />
              )}
            />
          </Collapse>
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('companyAddress')}</Typography>
            <Typography variant="body2" className={classes.topicDescription}>
              {translate('companyAddressDesc')}
            </Typography>
          </div>

          <Controller
            name="regionId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                value={regionOptions.find((region) => region.id === field.value)}
                selectOnFocus
                clearOnBlur
                blurOnSelect
                className={classes.inputField}
                onChange={(e, option) => {
                  if (!option) return;
                  setValue('regionId', option.id);
                }}
                getOptionSelected={(option, v) => option?.id === v?.id}
                options={regionOptions}
                getOptionLabel={(region) => region.nameAr || ''}
                renderInput={(props) => (
                  <TextField
                    error={!!errors.regionId?.type}
                    helperText={getErrorText(errors.regionId?.type)}
                    {...props}
                    label={translate('region')}
                    variant="outlined"
                  />
                )}
              />
            )}
          />

          {watch('regionId') ? (
            <Controller
              name="cityId"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <CrFillMissingInfoCity
                  regionId={watch('regionId')!}
                  onChange={(e, option) => {
                    if (!option) return;
                    setValue('cityId', option.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.cityId?.type}
                      helperText={getErrorText(errors.cityId?.type)}
                      label={translate('city')}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          ) : (
            <Controller
              name="cityId"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Autocomplete
                  selectOnFocus
                  clearOnBlur
                  blurOnSelect
                  className={classes.inputField}
                  options={[]}
                  disabled={!!formValues.cityId || !watch('regionId')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.cityId?.type}
                      helperText={getErrorText(errors.cityId?.type)}
                      label={translate('city')}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          )}

          {inputFields.map((field) => (
            <Controller
              key={field.name}
              name={field.name as keyof FormValues}
              control={control}
              rules={{
                required: true,
                ...(field.pattern ? { pattern: field.pattern } : {}),
              }}
              render={({ field: controllerField }) => (
                <TextField
                  {...controllerField}
                  disabled={!!formValues[field.name as keyof FormValues]}
                  variant="outlined"
                  className={classes.inputField}
                  error={!!errors[field.name as keyof FormValues]?.type}
                  helperText={getErrorText(errors[field.name as keyof FormValues]?.type)}
                  label={translate(field.label)}
                />
              )}
            />
          ))}

          <Controller
            name="googleLocation"
            control={control}
            render={({ field }) => (
              <div className={classes.map}>
                <Map
                  setValue={(obj) => {
                    setValue('googleLocation', obj);
                  }}
                />
                {errors.googleLocation?.type && (
                  <FormHelperText className={classes.error}>{getErrorText(errors.googleLocation?.type)}</FormHelperText>
                )}
              </div>
            )}
          />
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('socialMediaLinks')}</Typography>
          </div>

          <Controller
            name="linkedinUrl"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.linkedinUrl}
                variant="outlined"
                className={classes.inputField}
                label={translate('linkedinLink')}
              />
            )}
          />

          <Controller
            name="twitterUrl"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.twitterUrl}
                variant="outlined"
                className={classes.inputField}
                label={translate('twitterLink')}
              />
            )}
          />

          <Controller
            name="facebookUrl"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.facebookUrl}
                variant="outlined"
                className={classes.inputField}
                label={translate('facebookLink')}
                margin="normal"
              />
            )}
          />
          {hasTaxNumber && (
            <div className={classes.sectionHeader}>
              <Typography variant="h6" className={classes.field}>
                {translate('uploadDocuments')}
              </Typography>
              <Typography variant="body2" className={classes.field}>
                {hasTaxNumber === 'yes' ? translate('uploadTaxCertificate') : translate('uploadNonTaxCertificate')}
              </Typography>
              <div dir={direction} className={classes.uploadButtonSpace}>
                <label>
                  <Input
                    type="file"
                    onChange={handleFileChange}
                    accept=".png,.jpg,.jpeg,.pdf,.heic,.hevc,.heif"
                    multiple
                  />
                  <Button
                    size="large"
                    variant="outlined"
                    component="span"
                    color="secondary"
                    className={classes.uploadButton}
                  >
                    {translate('upload')}
                  </Button>
                </label>
                <Typography dir="rtl" variant="body2" className={classes.uploadButtonDesc}>
                  {translate('maximum5Files')} {translate('filesTypes')}
                </Typography>
              </div>
              <div dir={direction} className={classes.uploadedContainer}>
                {attachments.map((file, i) => (
                  <Button
                    key={i}
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    className={classes.attachment}
                    startIcon={<CheckGreyIcon fill="#148287" />}
                    endIcon={<CrossCloseGreyIcon fill="#9E9E9E" onClick={() => removeAttachment(i)} />}
                  >
                    {file.name}
                  </Button>
                ))}
              </div>
              {attachments.length < 1 ? <Hint text={translate('atLeastOneFile')} /> : null}
            </div>
          )}
          <FormControl error={!!errors.areTermsAccepted?.type}>
            <FormControlLabel
              control={
                <Controller
                  name="areTermsAccepted"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Checkbox
                      className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}
                      {...field}
                    />
                  )}
                />
              }
              label={
                <span className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}>
                  <span>{translate('termsAndConditionsPrefix')}</span>
                  <a href="/terms">{translate('termsAndConditions')}</a>
                  <span>{translate('termsAndConditionsPostfix')}</span>
                </span>
              }
              labelPlacement="end"
            />
          </FormControl>
        </div>

        <div className={classes.buttons}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              const field = document.querySelector('.Mui-error');
              field?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
            className={classes.button}
          >
            {translateCommon('save')}
          </Button>
          <Button color="secondary" size="large" onClick={goBack} className={classes.button}>
            {translate('goBack')}
          </Button>
        </div>
      </form>
    </div>
  );
};
