import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReAuth } from 'infrastructure/persistence/baseQueryWithReAuth';
import { Results, ListResult } from 'interfaces/Results';
import { Page, Search, Ordering } from 'infrastructure/persistence/types';
import { createAssessmentEndpoints } from 'infrastructure/services/api/assessment.endpoints.api';
import { createEstablishmentEndpoints } from 'infrastructure/services/api/establishment.endpoints';
import { CreateSelfAssessmentInput, Assessment, toAssessment } from 'types/Assessment';
import { EstablishmentApiTagType } from 'infrastructure/services/api/establishment.endpoints.tags';
import { EconomicActivity, EconomicActivityDto, toEconomicActivity } from 'types/EconomicActivity';
import { PCRequest, PCRequestDto, toPCRequest } from 'types/PCRequest';
import { EstablishmentInfo, EstablishmentInfoDto, establishmentInfoDtoToEstablishmentInfo } from 'types/Establishment';
import { TypeOfPlatform } from 'infrastructure/services/auth.type';

import { CompanyRepresentativeApiTagType, tagTypes } from './companyRepresentative.tags.api';
import { companyRepresentativeApiReducerPath } from '../companyRepresentative.config.api';
import { City, CityDto, Region, RegionDto, toCities, toRegions } from '../types/Localization';
import { MissingInfo, MissingInfoDto, MissingInfoEstablishmentDto, toMissingInfo } from '../types/MissingInfo';

export const companyRepresentativeApi = createApi({
  reducerPath: companyRepresentativeApiReducerPath,
  baseQuery: baseQueryWithReAuth(),
  tagTypes,
  endpoints: (build) => ({
    getCompanyList: build.query<ListResult<EstablishmentInfo>, { search: Search; parameters: Page }>({
      query: ({ parameters: { page = 1, pageSize = 5 }, search: { searchInfo } }) => ({
        url: `/establishment/collect/?page=${page}&page_size=${pageSize}&search=${searchInfo}`,
      }),
      providesTags: [CompanyRepresentativeApiTagType.EstablishmentInfo],
      transformResponse: (response: Results<EstablishmentInfoDto>) => ({
        count: response.count,
        results: response.results.map(establishmentInfoDtoToEstablishmentInfo),
      }),
    }),
    getPerformanceCardList: build.query<
      ListResult<PCRequest>,
      Page & { establishmentId: number; platform: TypeOfPlatform.OSH }
    >({
      query: ({ page = 1, pageSize = 5, ordering = Ordering.CreatedAt, establishmentId, platform }) => ({
        url: `/performance_card_request/?establishment_id=${establishmentId}&page=${page}&platform=${platform}&page_size=${pageSize}&ordering=${ordering}`,
      }),
      providesTags: (responseData) =>
        responseData
          ? [
              ...responseData.results.map(({ id }) => ({
                type: CompanyRepresentativeApiTagType.PCRequest,
                id,
              })),
            ]
          : [CompanyRepresentativeApiTagType.PCRequest],
      transformResponse: (response: Results<PCRequestDto>) => ({
        count: response.count,
        results: response.results.map(toPCRequest),
      }),
    }),
    createAssessment: build.mutation<Assessment, CreateSelfAssessmentInput>({
      query: (data) => ({
        url: `/self_assessment/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (err, result, { establishment_id }) => [
        CompanyRepresentativeApiTagType.EstablishmentInfo,
        { type: EstablishmentApiTagType.EstablishmentData, id: establishment_id },
      ],
      transformResponse: toAssessment,
    }),
    redoAssessment: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `/self_assessment/${id}/redo/`,
        method: 'POST',
      }),
      invalidatesTags: [CompanyRepresentativeApiTagType.EstablishmentInfo, EstablishmentApiTagType.EstablishmentData],
    }),
    getRegionList: build.query<Region[], void>({
      query: () => ({
        url: `/territory/regions/`,
      }),
      transformResponse: (response: RegionDto[]) => toRegions(response),
    }),
    getCitiesList: build.query<City[], number>({
      query: (regionId) => ({
        url: `/territory/cities/?region_id=${regionId}`,
      }),
      transformResponse: (response: CityDto[]) => toCities(response),
    }),
    getEstablishmentInfo: build.query<Partial<MissingInfo>, string>({
      query: (externalId) => ({
        url: `/establishment/get_by_ext_id/?est_ext_id=${externalId}`,
      }),
      transformResponse: (response: MissingInfoDto) => toMissingInfo(response),
    }),
    getEconomicActivitiesList: build.query<EconomicActivity[], void>({
      query: () => ({
        url: `/territory/economic_activities/`,
      }),
      transformResponse: (response: EconomicActivityDto[]) => response.map(toEconomicActivity),
    }),
    addEstablishmentInfo: build.mutation<
      void,
      { establishmentId: string | number; establishmentInfo: MissingInfoEstablishmentDto }
    >({
      query: ({ establishmentId, establishmentInfo }) => ({
        url: `/establishment/${establishmentId}/`,
        method: 'PUT',
        data: establishmentInfo,
      }),
      invalidatesTags: (err, result, { establishmentId }) => [
        CompanyRepresentativeApiTagType.EstablishmentInfo,
        { type: EstablishmentApiTagType.EstablishmentData, id: establishmentId },
      ],
    }),
    updateEstablishmentInfo: build.mutation<
      void,
      { establishmentId: string | number; establishmentInfo: MissingInfoEstablishmentDto }
    >({
      query: ({ establishmentId, establishmentInfo }) => ({
        url: `/establishment/update_info/?est_id=${establishmentId}`,
        method: 'PUT',
        data: establishmentInfo,
      }),
      invalidatesTags: (err, result, { establishmentId }) => [
        CompanyRepresentativeApiTagType.EstablishmentInfo,
        { type: EstablishmentApiTagType.EstablishmentData, id: establishmentId },
      ],
    }),
    VATAttachment: build.mutation<void, { establishmentId: number; data: any }>({
      query: ({ establishmentId, data }) => ({
        url: `/establishment/${establishmentId}/document/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [CompanyRepresentativeApiTagType.EstablishmentInfo],
    }),
  }),
});

export const companyRepresentativeEnhancedApi = companyRepresentativeApi
  .injectEndpoints({
    endpoints: createAssessmentEndpoints(companyRepresentativeApiReducerPath),
  })
  .injectEndpoints({
    endpoints: createEstablishmentEndpoints(companyRepresentativeApiReducerPath),
  });

export const {
  useGetCompanyListQuery,
  useGetPerformanceCardListQuery,
  useGetEstablishmentQuery,
  useGetAssessmentByIdQuery,
  useCreateAssessmentMutation,
  useGetSectionsByAssessmentIdQuery,
  useGetQuestionsByAssessmentIdAndSectionIdQuery,
  useGetAnswersByAssessmentIdAndSectionIdQuery,
  useCreateSAAnswerMutation,
  useUpdateSAAnswerMutation,
  useUploadSelfAssessmentAttachmentMutation,
  useDeleteSelfAssessmentAttachmentMutation,
  useRedoAssessmentMutation,
  useSubmitAssessmentMutation,

  // Missing info form
  useGetCitiesListQuery,
  useGetRegionListQuery,
  useGetEstablishmentInfoQuery,
  useGetEconomicActivitiesListQuery,
  useAddEstablishmentInfoMutation,
  useUpdateEstablishmentInfoMutation,

    // VAT Attachment
    useVATAttachmentMutation,
} = companyRepresentativeEnhancedApi;
