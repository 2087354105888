import { AssessmentStatus } from 'types/AssessmentStatus';

export type EstablishmentDto = {
  additional_phone_number: string;
  city_id: number;
  city_name_ar: string;
  city_name_en: string;
  company_representative_name: string;
  district: string;
  economic_activity_id: number;
  economic_activity_name_ar: string;
  economic_activity_name_en: string;
  email: string;
  ext_id: string;
  extension: string;
  mobile: string;
  facebook_url: string;
  id: number;
  is_updated: boolean;
  latitude: string;
  linkedin_url: string;
  longitude: string;
  name_ar: string;
  name_en: string;
  phone: string;
  phone_number: string;
  region_id: number;
  region_name_ar: string;
  region_name_en: string;
  street: string;
  tax_number: string;
  twitter_url: string;
  self_assessment_previous_obj_id: number | null;
  self_assessment_current_obj_id_ca: number;
  self_assessment_current_obj_id_osh: number;
  self_assessment_previous_obj_status: number;
  self_assessment_current_obj_status_osh: number;
  self_assessment_current_obj_status_ca: number;
  self_assessment_previous_obj_score: number | null;
  self_assessment_current_obj_score: number;
  performance_card_request_id_osh: number | null;
  performance_card_request_id_ca: number | null;
  self_assessment_attempts_osh: number;
  self_assessment_attempts_ca: number;
  self_assessment_attempts_max: number;
  self_assessment_attempts_left_osh: number;
  self_assessment_attempts_left_ca: number;
  performance_card_next_attempt_possible_at_osh: string;
  performance_card_next_attempt_possible_at_ca: string;
  totalLaborerCount: string;
  waselarea: string;
  waselcity: string;
  waselstreet: string;
  waselunitNo: number;
  waselzipCode: number;
  commercialRegistration: number;
};

export type Establishment = {
  totalLaborerCount: string;
  additionalPhoneNumber: string;
  cityId: number;
  cityNameAr: string;
  cityNameEn: string;
  companyRepresentativeName: string;
  district: string;
  economicActivityId: number;
  economicActivityNameAr: string;
  economicActivityNameEn: string;
  email: string;
  extId: string;
  extension: string;
  mobile: string;
  facebookUrl: string;
  id: number;
  isUpdated: boolean;
  latitude: string;
  linkedinUrl: string;
  longitude: string;
  nameAr: string;
  nameEn: string;
  phone: string;
  phoneNumber: string;
  regionId: number;
  regionNameAr: string;
  regionNameEn: string;
  street: string;
  taxNumber: string;
  twitterUrl: string;
  assessmentPreviousObjId: number | null;
  assessmentCurrentObjIdCA: number;
  assessmentCurrentObjIdOSH: number;
  assessmentPreviousObjStatus: AssessmentStatus | null;
  assessmentCurrentObjStatusOSH: AssessmentStatus | null;
  assessmentCurrentObjStatusCA: AssessmentStatus | null;
  assessmentPreviousObjScore: number | null;
  assessmentCurrentObjScore: number | null;
  pcRequestIdOSH: number | null;
  pcRequestIdCA: number | null;
  assessmentAttemptsOSH: number;
  assessmentAttemptsCA: number;
  assessmentAttemptsMax: number;
  assessmentAttemptsLeftOSH: number;
  assessmentAttemptsLeftCA: number;
  pcNextAttemptPossibleAtOSH: string | null;
  pcNextAttemptPossibleAtCA: string | null;
  buildingNumber: number;
  postalCode: number;
  additionalNumber: string;
  commercialRegistration: number;
};

export const toEstablishment = ({
  additional_phone_number,
  city_id,
  city_name_ar,
  city_name_en,
  company_representative_name,
  economic_activity_id,
  economic_activity_name_ar,
  economic_activity_name_en,
  email,
  ext_id,
  extension,
  mobile: string,
  facebook_url,
  id,
  is_updated,
  latitude,
  linkedin_url,
  longitude,
  name_ar,
  name_en,
  phone,
  phone_number,
  region_id,
  region_name_ar,
  region_name_en,
  tax_number,
  twitter_url,
  self_assessment_previous_obj_id,
  self_assessment_current_obj_id_ca,
  self_assessment_current_obj_id_osh,
  self_assessment_previous_obj_status,
  self_assessment_current_obj_status_osh,
  self_assessment_current_obj_status_ca,
  self_assessment_previous_obj_score,
  self_assessment_current_obj_score,
  performance_card_request_id_osh,
  performance_card_request_id_ca,
  self_assessment_attempts_osh,
  self_assessment_attempts_ca,
  self_assessment_attempts_left_osh,
  self_assessment_attempts_left_ca,
  self_assessment_attempts_max,
  totalLaborerCount,
  performance_card_next_attempt_possible_at_osh,
  performance_card_next_attempt_possible_at_ca,
  waselarea,
  waselstreet,
  waselunitNo,
  waselzipCode,
  commercialRegistration,
}: EstablishmentDto): Establishment => ({
  totalLaborerCount: totalLaborerCount,
  additionalPhoneNumber: additional_phone_number,
  cityId: city_id,
  cityNameAr: city_name_ar,
  cityNameEn: city_name_en,
  companyRepresentativeName: company_representative_name,
  district: waselarea,
  economicActivityId: economic_activity_id,
  economicActivityNameAr: economic_activity_name_ar,
  economicActivityNameEn: economic_activity_name_en,
  email,
  extId: ext_id,
  extension,
  mobile: string,
  facebookUrl: facebook_url,
  id,
  isUpdated: is_updated,
  latitude,
  linkedinUrl: linkedin_url,
  longitude,
  nameAr: name_ar,
  nameEn: name_en,
  phone,
  phoneNumber: phone_number,
  regionId: region_id,
  regionNameAr: region_name_ar,
  regionNameEn: region_name_en,
  street: waselstreet,
  taxNumber: tax_number,
  twitterUrl: twitter_url,
  assessmentPreviousObjId: self_assessment_previous_obj_id,
  assessmentCurrentObjIdCA: self_assessment_current_obj_id_ca,
  assessmentCurrentObjIdOSH: self_assessment_current_obj_id_osh,
  assessmentPreviousObjStatus: self_assessment_previous_obj_status,
  assessmentCurrentObjStatusOSH: self_assessment_current_obj_status_osh,
  assessmentCurrentObjStatusCA: self_assessment_current_obj_status_ca,
  assessmentPreviousObjScore: self_assessment_previous_obj_score,
  assessmentCurrentObjScore: self_assessment_current_obj_score,
  pcRequestIdOSH: performance_card_request_id_osh,
  pcRequestIdCA: performance_card_request_id_ca,
  assessmentAttemptsOSH: self_assessment_attempts_osh,
  assessmentAttemptsCA: self_assessment_attempts_ca,
  assessmentAttemptsLeftOSH: self_assessment_attempts_left_osh,
  assessmentAttemptsLeftCA: self_assessment_attempts_left_ca,
  assessmentAttemptsMax: self_assessment_attempts_max,
  pcNextAttemptPossibleAtOSH: performance_card_next_attempt_possible_at_osh,
  pcNextAttemptPossibleAtCA: performance_card_next_attempt_possible_at_ca,
  buildingNumber: waselunitNo,
  postalCode: waselzipCode,
  additionalNumber: '',
  commercialRegistration: commercialRegistration,
});

export type EstablishmentInfo = Pick<
  Establishment,
  | 'extId'
  | 'nameAr'
  | 'assessmentAttemptsOSH'
  | 'assessmentAttemptsCA'
  | 'assessmentAttemptsMax'
  | 'assessmentAttemptsLeftOSH'
  | 'assessmentAttemptsLeftCA'
  | 'assessmentPreviousObjId'
  | 'assessmentPreviousObjStatus'
  | 'assessmentCurrentObjIdOSH'
  | 'assessmentCurrentObjIdCA'
  | 'assessmentCurrentObjStatusOSH'
  | 'assessmentCurrentObjStatusCA'
> & { establishmentId: number | null };

export type EstablishmentInfoDto = Pick<
  EstablishmentDto,
  | 'name_ar'
  | 'self_assessment_attempts_osh'
  | 'self_assessment_attempts_ca'
  | 'self_assessment_attempts_left_osh'
  | 'self_assessment_attempts_left_ca'
  | 'self_assessment_attempts_max'
  | 'self_assessment_previous_obj_id'
  | 'self_assessment_previous_obj_status'
  | 'self_assessment_current_obj_id_ca'
  | 'self_assessment_current_obj_id_osh'
  | 'self_assessment_current_obj_status_osh'
  | 'self_assessment_current_obj_status_ca'
> & { establishment_id: number | null; establishment_ext_id: string };

export const establishmentToEstablishmentInfo = (establishment: Establishment): EstablishmentInfo => ({
  extId: establishment.extId,
  nameAr: establishment.nameAr,
  assessmentAttemptsOSH: establishment.assessmentAttemptsOSH,
  assessmentAttemptsCA: establishment.assessmentAttemptsCA,
  assessmentAttemptsMax: establishment.assessmentAttemptsMax,
  assessmentAttemptsLeftOSH: establishment.assessmentAttemptsLeftOSH,
  assessmentAttemptsLeftCA: establishment.assessmentAttemptsLeftCA,
  assessmentPreviousObjId: establishment.assessmentPreviousObjId,
  assessmentCurrentObjIdOSH: establishment.assessmentCurrentObjIdOSH,
  assessmentCurrentObjIdCA: establishment.assessmentCurrentObjIdCA,
  assessmentPreviousObjStatus: establishment.assessmentPreviousObjStatus,
  assessmentCurrentObjStatusOSH: establishment.assessmentCurrentObjStatusOSH,
  assessmentCurrentObjStatusCA: establishment.assessmentCurrentObjStatusCA,
  establishmentId: establishment.id,
});

export const establishmentDtoToEstablishmentInfo = (establishmentDto: EstablishmentInfoDto): EstablishmentInfo => ({
  extId: establishmentDto.establishment_ext_id,
  nameAr: establishmentDto.name_ar,
  assessmentAttemptsOSH: establishmentDto.self_assessment_attempts_osh,
  assessmentAttemptsCA: establishmentDto.self_assessment_attempts_ca,
  assessmentAttemptsMax: establishmentDto.self_assessment_attempts_max,
  assessmentAttemptsLeftOSH: establishmentDto.self_assessment_attempts_left_osh,
  assessmentAttemptsLeftCA: establishmentDto.self_assessment_attempts_left_ca,
  assessmentPreviousObjId: establishmentDto.self_assessment_previous_obj_id,
  assessmentCurrentObjIdCA: establishmentDto.self_assessment_current_obj_id_ca,
  assessmentCurrentObjIdOSH: establishmentDto.self_assessment_current_obj_id_osh,
  assessmentPreviousObjStatus: establishmentDto.self_assessment_previous_obj_status,
  assessmentCurrentObjStatusOSH: establishmentDto.self_assessment_current_obj_status_osh,
  assessmentCurrentObjStatusCA: establishmentDto.self_assessment_current_obj_status_ca,
  establishmentId: establishmentDto.establishment_id,
});

export const establishmentInfoDtoToEstablishmentInfo = (
  establishmentInfoDto: EstablishmentInfoDto
): EstablishmentInfo => ({
  extId: establishmentInfoDto.establishment_ext_id,
  nameAr: establishmentInfoDto.name_ar,
  assessmentAttemptsOSH: establishmentInfoDto.self_assessment_attempts_osh,
  assessmentAttemptsCA: establishmentInfoDto.self_assessment_attempts_ca,
  assessmentAttemptsMax: establishmentInfoDto.self_assessment_attempts_max,
  assessmentAttemptsLeftOSH: establishmentInfoDto.self_assessment_attempts_left_osh,
  assessmentAttemptsLeftCA: establishmentInfoDto.self_assessment_attempts_left_ca,
  assessmentPreviousObjId: establishmentInfoDto.self_assessment_previous_obj_id,
  assessmentCurrentObjIdCA: establishmentInfoDto.self_assessment_current_obj_id_ca,
  assessmentCurrentObjIdOSH: establishmentInfoDto.self_assessment_current_obj_id_osh,
  assessmentPreviousObjStatus: establishmentInfoDto.self_assessment_previous_obj_status,
  assessmentCurrentObjStatusOSH: establishmentInfoDto.self_assessment_current_obj_status_osh,
  assessmentCurrentObjStatusCA: establishmentInfoDto.self_assessment_current_obj_status_ca,
  establishmentId: establishmentInfoDto.establishment_id,
});
